<template>
	<div class="contributePay" :class="['contributePay', className('contributePay-')]">
		<w-navTab titleText="会费支付"></w-navTab>
		<div class="container">
			<div class="contributePay-title">立即支付</div>
			<div class="contributePay-item">
				<div class="item-top">
					<div class="title">支付金额</div>
					<div class="text">100元</div>
				</div>
				<div class="item-top">
					<div class="bottom-text">会员有效期：3年</div>
				</div>
			</div>
			<div class="contributePay-item">
				<div class="item-top">
					<div class="title">支付方式</div>
				</div>
				<div class="item-top">
					<div class="bottom-text">
						<van-icon name="wechat-pay" color="#6BCC03" size="28px" style="margin-right: 5px;" />
						微信支付
					</div>
					<van-checkbox v-model="checked" v-if="districtType() == 1" checked-color="#3377ff"></van-checkbox>
					<van-checkbox v-model="checked" v-else checked-color="#ff6969"></van-checkbox>
				</div>
			</div>
			<div class="footer-btn" @click="onPay">立即支付</div>
		</div>
	</div>
</template>

<script>
	import Vant from '@/vendor/vant.js';
	import UserApi from '@/api/user';
	import IndexApi from '@/api/index';
	export default {
		inject: ['themeColor', 'className', 'districtType'],
		name: 'ContributePay',
		data() {
			return {
				checked: true,
				piace: ''
			};
		},
		methods: {
			onPay() {
				UserApi.userPlaceOrder().then(
					result => {
						this.piace = result.data;
						wx.chooseWXPay({
							timestamp: this.piace.timeStamp,
							nonceStr: this.piace.nonceStr,
							package: this.piace.package,
							signType: this.piace.signType,
							paySign: this.piace.paySign,
							success: function(res) {
								Vant.Toast.success('支付成功');
								this.$router.replace({
									name: 'Contribute'
								});
							},

						});
					},
					error => {
						Vant.Toast(error.msg);
					}
				);

			}
		}
	};
</script>

<style lang="less" scoped>
	.contributePay-city {
		.contributePay-title {
			&:before {
				background: #3377ff;
			}
		}

		.contributePay-item {
			.item-top {
				.text {
					color: #3377ff;
				}
			}
		}

		.footer-btn {
			background: #3377FF;
		}
	}

	.contributePay-district {
		.contributePay-title {
			&:before {
				background: #ff6969;
			}
		}

		.contributePay-item {
			.item-top {
				.text {
					color: #ff6969;
				}
			}
		}

		.footer-btn {
			background: #ff6969;
		}
	}

	.contributePay {
		.container {
			padding: 20px 10px;
			box-sizing: border-box;

			.contributePay-title {
				font-size: 18px;
				line-height: 30px;
				color: #333;
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				&:before {
					content: '';
					display: block;
					width: 3px;
					height: 20px;
					border-radius: 100px;
					margin-right: 5px;
				}
			}

			.contributePay-item {
				padding: 5px 10px 0;
				box-sizing: border-box;
				background: #fff;
				border-radius: 6px;
				margin-bottom: 10px;

				.item-top:last-child {
					border-bottom: 0;
				}

				.item-top {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px 0;
					border-bottom: 0.5px solid #eee;

					.title {
						font-size: 18px;
						line-height: 24px;
						color: #333;
					}

					.text {
						font-size: 13px;
						line-height: 16px;
					}

					.bottom-text {
						display: flex;
						align-items: center;
						font-size: 16px;
						line-height: 16px;
						color: #666;
					}
				}
			}

			.footer-btn {
				width: 100%;
				font-weight: bold;
				font-size: 18px;
				line-height: 30px;
				color: #fff;
				padding: 10px 0;
				border-radius: 100px;
				text-align: center;
				margin-top: 40px;
			}
		}
	}
</style>
